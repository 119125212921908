// fonts-blog.scss is not used on Pfisterfaucets.com. It's copied into the pfister-blog repository
// and served from the blog theme to allow the blog to load local font files

@font-face {
	font-family: 'Pfont';
	src: url('../fonts/Pfont-Regular.woff2') format('woff2'),
	url('../fonts/Pfont-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'Pfont';
	src: url('../fonts/Pfont-Bold.woff2') format('woff2'),
	url('../fonts/Pfont-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;

}

@font-face {
	font-family: 'Pfont';
	src: url('../fonts/Pfont-Light.woff2') format('woff2'),
	url('../fonts/Pfont-Light.woff') format('woff');
	font-weight: 200;
	font-style: normal;

}

@font-face {
	font-family: 'Pfont';
	src: url('../fonts/Pfont-Italic.woff2') format('woff2'),
	url('../fonts/Pfont-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Pfont';
	src: url('../fonts/Pfont-BoldItalic.woff2') format('woff2'),
	url('../fonts/Pfont-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;

}

@font-face {
	font-family: 'Pfont';
	src: url('../fonts/Pfont-LightItalic.woff2') format('woff2'),
	url('../fonts/Pfont-LightItalic.woff') format('woff');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: '../fonts/claire_handlight';
	src: url('../fonts/clairehandlight-webfont.eot');
	src: url('../fonts/clairehandlight-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/clairehandlight-webfont.woff2') format('woff2'),
	url('../fonts/clairehandlight-webfont.woff') format('woff'),
	url('../fonts/clairehandlight-webfont.ttf') format('truetype'),
	url('../fonts/clairehandlight-webfont.svg#claire_handlight') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'claire_handregular';
	src: url('../fonts/clairehandregular-webfont.eot');
	src: url('../fonts/clairehandregular-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/clairehandregular-webfont.woff2') format('woff2'),
	url('../fonts/clairehandregular-webfont.woff') format('woff'),
	url('../fonts/clairehandregular-webfont.ttf') format('truetype'),
	url('../fonts/clairehandregular-webfont.svg#claire_handregular') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'claire_handbold';
	src: url('../fonts/clairehandbold-webfont.eot');
	src: url('../fonts/clairehandbold-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/clairehandbold-webfont.woff2') format('woff2'),
	url('../fonts/clairehandbold-webfont.woff') format('woff'),
	url('../fonts/clairehandbold-webfont.ttf') format('truetype'),
	url('../fonts/clairehandbold-webfont.svg#claire_handbold') format('svg');
	font-weight: normal;
	font-style: normal;

}

// IcoMoon font
@font-face {
	font-family: 'icomoon';
	src:  url('../fonts/icomoon.eot?ndnpfq');
	src:  url('../fonts/icomoon.eot?ndnpfq#iefix') format('embedded-opentype'),
	url('../fonts/icomoon.woff2?ndnpfq') format('woff2'),
	url('../fonts/icomoon.ttf?ndnpfq') format('truetype'),
	url('../fonts/icomoon.woff?ndnpfq') format('woff'),
	url('../fonts/icomoon.svg?ndnpfq#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}